import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withSize } from 'react-sizeme';
import { setScreenWidth } from '../../redux/size/sizeActions';
import NavigationRoutes from '../_navigation/NavigationRoutes';

const Main = ({ size: { width } }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    width && dispatch(setScreenWidth(width));
    // eslint-disable-next-line
  }, [width]);
  return <NavigationRoutes />;
};

export default withSize()(Main);
