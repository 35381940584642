import { useContext, createContext } from 'react';
import { useLanguage } from '../hooks/useLanguage';

const LangContext = createContext();
export const useLangContext = () => useContext(LangContext);

const LangProvider = ({ children }) => {
  const [language, setLanguage, list] = useLanguage();
  return (
    <LangContext.Provider value={{ language, setLanguage, list }}>
      {children}
    </LangContext.Provider>
  );
};

export default LangProvider;
