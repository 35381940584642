import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
  signupSuccess,
  signupError,
  signinSuccess,
  signinError,
  logoutSuccess,
  logoutError,
  refreshSuccess,
  refreshError,
  userSuccess,
  clearError,
  setNewPasswordToken,
  sendEmailSuccess,
  sendEmailError,
  setNewPasswordError,
  setNewPasswordSuccess,
  resetHasNewPassword,
  resetNewPasswordToken,
} from './authActions';

import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import {
  addProjectSuccess,
  deleteProjectSuccess,
} from '../projects/projectActions';

const initialUserState = {
  email: '',
  id: '',
};

const userReducer = createReducer(initialUserState, {
  [signupSuccess]: (_, { payload }) => payload.user,
  [userSuccess]: (_, { payload }) => payload,
  [refreshSuccess]: (state, { payload }) => ({ ...state, ...payload }),
  [logoutSuccess]: () => initialUserState,
});

const initToken = { accessToken: '', refreshToken: '', sid: '' };
const token = createReducer(initToken, {
  [signupSuccess]: (_, { payload }) => payload,
  [signinSuccess]: (_, { payload }) => payload,
  [refreshSuccess]: (_, { payload }) => payload,
  [logoutSuccess]: () => initToken,
});

const initialNewPasswordState = {
  token: null,
  mustTakeToken: false,
  hasNewPassword: false,
};
const newPassword = createReducer(initialNewPasswordState, {
  [setNewPasswordToken]: (state, { payload }) => ({
    ...state,
    token: payload,
    mustTakeToken: false,
  }),
  [resetNewPasswordToken]: () => initialNewPasswordState,
  [sendEmailSuccess]: state => ({
    ...state,
    mustTakeToken: true,
  }),
  [setNewPasswordSuccess]: state => ({
    ...state,
    token: null,
    hasNewPassword: true,
  }),
  [resetHasNewPassword]: state => ({
    ...state,
    hasNewPassword: false,
  }),
  [logoutSuccess]: () => initialNewPasswordState,
});

const ownProjects = createReducer([], {
  [signinSuccess]: (_, { payload }) => payload.ownProjects,
  [addProjectSuccess]: (state, { payload }) => [...state, payload._id],
  [deleteProjectSuccess]: (state, { payload }) =>
    state.filter(el => el !== payload),
  [logoutSuccess]: () => [],
});

const setError = (_, { payload }) => payload;

const error = createReducer('', {
  [signupError]: setError,
  [signinError]: setError,
  [logoutError]: setError,
  [refreshError]: setError,
  [sendEmailError]: setError,
  [setNewPasswordError]: setError,
  [clearError]: () => '',
});

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['email'],
};

const newPasswordPersistConfig = {
  key: 'newPassword',
  storage,
  whitelist: ['token'],
};

const authReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  token,
  newPassword: persistReducer(newPasswordPersistConfig, newPassword),
  ownProjects,
  error,
});

export default authReducer;
