import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setNewPasswordToken } from '../../redux/auth/authActions';
import { isAuthSelector } from '../../redux/auth/authSelectors';
import { routes } from '../../routes/mainRoutes';

const MainRedirect = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const isAuth = useSelector(isAuthSelector);

  const searchParams = new URLSearchParams(search);

  pathname === '/password/reset/' &&
    dispatch(setNewPasswordToken(searchParams.get('token')));

  return !isAuth ? (
    pathname === '/password/reset/' ? (
      <Redirect to={routes.RESET_PASSWORD} />
    ) : (
      <Redirect to={routes.LOGIN} />
    )
  ) : (
    <Redirect to={routes.PROJECTS} />
  );
};

export default MainRedirect;
