import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import mainRoutes from '../../routes/mainRoutes';
import PrivateRoute from '../routes/PrivateRoute';
import PublicRoute from '../routes/PublicRoute';
import MainRedirect from './MainRedirect';
import Spinner from '../spinner/Spinner';
import { isAuthSelector } from '../../redux/auth/authSelectors';

const NavigationRoutes = () => {
  const isAuth = useSelector(isAuthSelector);

  return (
    <Suspense fallback={<Spinner />}>
      <main>
        <Switch>
          {mainRoutes.map(route =>
            route.isPrivate ? (
              <PrivateRoute {...route} isAuth={isAuth} key={route.path} />
            ) : (
              <PublicRoute {...route} isAuth={isAuth} key={route.path} />
            ),
          )}
          <MainRedirect />
        </Switch>
      </main>
    </Suspense>
  );
};

export default NavigationRoutes;
