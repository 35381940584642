import { useLangContext } from '../../context/LangProvider';
import s from './LanguageSwitcher.module.scss';

const LanguageSwitcher = () => {
  const { language, list, setLanguage } = useLangContext();

  const onHandleChange = e => {
    setLanguage(e.target.value);
  };
  
  return (
    <div className={s.container}>
      <select
        defaultValue={language.title}
        onChange={onHandleChange}
        className={s.select}
      >
        {list.map(item => (
          <option className={s.option} value={item.title} key={item.name}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
