import { createAction } from '@reduxjs/toolkit';

const signupRequest = createAction('auth/signupRequest');
const signupSuccess = createAction('auth/signupSuccess');
const signupError = createAction('auth/signupError');

const signinRequest = createAction('auth/signinRequest');
const signinSuccess = createAction('auth/signinSuccess');
const signinError = createAction('auth/signinError');

const logoutRequest = createAction('auth/logoutRequest');
const logoutSuccess = createAction('auth/logoutSuccess');
const logoutError = createAction('auth/logoutError');

const refreshRequest = createAction('auth/refreshRequest');
const refreshSuccess = createAction('auth/refreshSuccess');
const refreshError = createAction('auth/refreshError');

const sendEmailRequest = createAction('auth/sendEmailRequest');
const sendEmailSuccess = createAction('auth/sendEmailSuccess');
const sendEmailError = createAction('auth/sendEmailError');

const setNewPasswordRequest = createAction('auth/setNewPasswordRequest');
const setNewPasswordSuccess = createAction('auth/setNewPasswordSuccess');
const setNewPasswordError = createAction('auth/setNewPasswordError');

const setNewPasswordToken = createAction('auth/setNewPasswordToken');
const resetNewPasswordToken = createAction('auth/resetNewPasswordToken');
const resetHasNewPassword = createAction('auth/resetHasNewPassword');

const userSuccess = createAction('members/userSuccess');

const clearError = createAction('auth/clearError');

export {
  signupRequest,
  signupSuccess,
  signupError,
  signinRequest,
  signinSuccess,
  signinError,
  logoutRequest,
  logoutSuccess,
  logoutError,
  refreshRequest,
  refreshSuccess,
  refreshError,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailError,
  setNewPasswordRequest,
  setNewPasswordSuccess,
  setNewPasswordError,
  setNewPasswordToken,
  resetNewPasswordToken,
  resetHasNewPassword,
  userSuccess,
  clearError,
};
