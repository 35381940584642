const getUserEmail = state => state.auth.user?.email;
const isAuthSelector = state => state.auth.token?.accessToken;
const getAuthError = state => state.auth?.error;
const getOwnProjects = state => state.auth.ownProjects;
const getMustTakeToken = state => state.auth.newPassword.mustTakeToken;
const getHasNewPassword = state => state.auth.newPassword.hasNewPassword;

export {
  getUserEmail,
  isAuthSelector,
  getAuthError,
  getOwnProjects,
  getMustTakeToken,
  getHasNewPassword,
};
