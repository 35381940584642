import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserMenu from '../userMenu/UserMenu';
import Container from '../shared/container/Container';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import { isAuthSelector } from '../../redux/auth/authSelectors';
import logo from '../../assets/icons/logo.svg';
import s from './Header.module.scss';

const Header = () => {
  const isAuth = useSelector(isAuthSelector);

  return (
    <header className={s.header}>
      <Container>
        <div className={s.wrapper}>
          <NavLink to="/projects">
            <img src={logo} alt="" className={s.logo} width="140" height="42" />
          </NavLink>
          {isAuth && <UserMenu />}
          <LanguageSwitcher />
        </div>
      </Container>
    </header>
  );
};

export default Header;
