import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { setScreenWidth } from './sizeActions';
import { logoutSuccess } from '../auth/authActions';

const screenWidthReducer = createReducer(0, {
  [setScreenWidth]: (_, { payload }) => payload,
  [logoutSuccess]: () => 0,
});

export default combineReducers({
  screenWidth: screenWidthReducer,
});
