import { useSelector } from 'react-redux';
import LangProvider from '../../context/LangProvider';
import Header from '../header/Header';
import Main from '../main/Main';
import Spinner from '../spinner/Spinner';
import { getIsLoading } from '../../redux/loading/loadingSelectors';

const App = () => {
  const isLoading = useSelector(getIsLoading);

  return (
    <LangProvider>
      {isLoading && <Spinner />}
      <Header />
      <Main />
    </LangProvider>
  );
};
export default App;
