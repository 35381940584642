const english = {
  title: 'english',
  name: 'EN',
  userMenu: {
    exit: 'Log out',
  },
  validation: {
    plannedHours: {
      min: 'Value must be from 1 to 8',
      max: 'Value must be from 1 to 8',
      required: 'Required value',
    },
    mustBeNum: 'Value must be a number',
    email: 'Input correct email',
    passwordLength: 'Must be 8 chars or more',
    wrongPassword: 'Wrong password',
    userExists: 'A user with this name already exists',
    required: 'Required value',
    getMessageByStringLength: (from, to) =>
      `Must be from ${from} to ${to} chars`,
    getMessageByValueInterval: (from, to) =>
      `Value must be between ${from} and ${to}`,
  },
  authForm: {
    signupTitle: 'Registration',
    signinTitle: 'Authentication',
    sendEmailTitle: 'Send your account email',
    newPasswordTitle: 'Input new password',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    sendEmail: 'Email',
    newPassword: 'New password',
    newConfirm: 'Confirm new password',
    signupButton: 'Sign up',
    signinButton: 'Sign in',
    sendEmailButton: 'Send',
    signupQuestion: 'Have you got an account?',
    signinQuestion: "Haven't got an account yet?",
    resetPassword: 'Forgot password?',
    signupLink: 'Sign in',
    signinLink: 'Sign up',
    resetPasswordLink: 'Reset',
    sendEmailMessage: 'A password reset link has been sent to your e-mail',
    passwordResetMessage: 'You can log in using a new password',
    notMatchPasswordsMessage: 'Passwords do not match',
  },
  sidebarModal: {
    createButton: 'Create',
    cancelButton: 'Cancel',
  },
  projectsPage: {
    pageTitle: 'Projects',
    addButton: 'Create project',
    projectCollection:
      'This folder is empty. Use "Create project" button to add new one',
  },
  projectsPageForm: {
    formTitle: 'Project creation',
    projectName: 'Project name',
    projectDescription: 'Project description',
  },
  projectPage: {
    addSprintButton: 'Create sprint',
    addMembersButton: 'Add members',
    sprintCollection:
      'This folder is empty. Use "Create sprint" button to add new one',
  },
  projectPageSidebar: {
    goBack: 'Show projects',
    addProjectButton: 'Create project',
  },
  projectPageForm: {
    formTitle: 'Sprint creation',
    sprintName: 'Sprint name',
    prevDays: 'Previous days',
    startDate: 'Start date',
    expireDate: 'Finish date', // изменил из Expire date
    duration: 'Duration',
  },
  addMembersForm: {
    formTitle: 'Add members',
    email: 'Enter your email',
    members: 'Added users:',
  },
  sprintPageSidebar: {
    goBack: 'Show sprints',
    addSprintButton: 'Create sprint',
  },
  sprintPageHeader: {
    addTaskButton: 'Create task',
    taskName: 'Task',
    plannedHours: 'Scheduled hours',
    spentHoursPerDay: 'Spent  hours / day',
    spentHoursTotally: 'Spent hours',
    statusTitle: 'Not done/Done',
  },
  sprintPageForm: {
    formTitle: 'Task creation',
    taskName: 'Task name',
    plannedHours: 'Scheduled hours',
  },
  sprintPage: {
    emptyTaskCollection:
      'This folder is empty. Use "Create task" button to add new one',
    noSuchTask: 'There is no such task',
    editProjectName: 'Enter new name',
  },
  editTitleForm: {
    label: 'Enter new title',
  },
  searchFormPlaceholder: 'Search...',
  locale: 'en',
};

export { english };
