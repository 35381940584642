import { lazy } from 'react';

export const routes = {
  LOGIN: '/signin',
  REGISTER: '/signup',
  SEND_EMAIL: '/send-email',
  RESET_PASSWORD: '/reset-password',
  PROJECTS: '/projects',
  SINGLE_PROJECT: '/projects/:projectId',
  SINGLE_SPRINT: '/projects/:projectId/sprints/:sprintId',
};

const mainRoutes = [
  {
    path: routes.REGISTER,
    name: 'SignUp',
    exact: true,
    component: lazy(() => import('../pages/authPage/AuthPage')),
    isPrivate: false,
    isRestricted: true,
  },
  {
    path: routes.LOGIN,
    name: 'SignIn',
    exact: true,
    component: lazy(() => import('../pages/authPage/AuthPage')),
    isPrivate: false,
    isRestricted: true,
  },
  {
    path: routes.SEND_EMAIL,
    name: 'SendEmail',
    exact: true,
    component: lazy(() => import('../pages/authPage/AuthPage')),
    isPrivate: false,
    isRestricted: true,
  },
  {
    path: routes.RESET_PASSWORD,
    name: 'ResetPassword',
    exact: true,
    component: lazy(() => import('../pages/authPage/AuthPage')),
    isPrivate: false,
    isRestricted: true,
  },
  {
    path: routes.PROJECTS,
    name: 'Projects',
    exact: true,
    component: lazy(() => import('../pages/projectsPage/ProjectsPage')),
    isPrivate: true,
    isRestricted: false,
  },
  {
    path: routes.SINGLE_PROJECT,
    name: 'Sprints',
    exact: true,
    component: lazy(() =>
      import('../pages/projectDetailsPage/ProjectDetailsPage'),
    ),
    isPrivate: true,
    isRestricted: false,
  },
  {
    path: routes.SINGLE_SPRINT,
    name: 'SingleSprint',
    exact: true,
    component: lazy(() =>
      import('../pages/sprintDetailsPage/SprintDetailsPage'),
    ),
    isPrivate: true,
    isRestricted: false,
  },
];

export default mainRoutes;
