const ukrainian = {
  title: 'ukrainian',
  name: 'UA',
  userMenu: {
    exit: 'Вихiд',
  },
  validation: {
    plannedHours: {
      min: 'Значення повинно бути вiд 1 до 8',
      max: 'Значення повинно бути вiд 1 до 8',
      required: "Обов'язкове значення",
    },
    mustBeNum: 'Значення повинно бути числом',
    email: 'Вкажіть коректний емейл',
    passwordLength: 'Потрiбно ввести 8 або бiльше символiв ',
    wrongPassword: 'Пароль не вiрний',
    userExists: 'Користувач з таким іменем вже існує',
    required: "Обов'язкове значення",
    getMessageByStringLength: (from, to) =>
      `Потрiбно ввести вiд ${from} до ${to} символiв`,
    getMessageByValueInterval: (from, to) =>
      `Потрiбно ввести значення вiд ${from} до ${to}`,
  },
  authForm: {
    signupTitle: 'Реєстрація',
    signinTitle: 'Вхід',
    sendEmailTitle: 'Надішліть електронну адресу свого облікового запису',
    newPasswordTitle: 'Введiть новий пароль',
    email: 'Email',
    password: 'Пароль',
    confirmPassword: 'Підтвердіть пароль',
    sendEmail: 'Email',
    newPassword: 'Новий пароль',
    newConfirm: 'Підтвердіть новий пароль',
    signupButton: 'Зареєструватися',
    signinButton: 'Увійти',
    sendEmailButton: 'Надiслати',
    signupQuestion: 'Маєте акаунт?',
    signinQuestion: 'Немає акаунту?',
    signupLink: 'Увійти',
    signinLink: 'Зареєструватись',
    resetPassword: "Не пам'ятаєте пароль?",
    resetPasswordLink: 'Вiдновити',
    sendEmailMessage:
      'Посилання для відновлення пароля надіслано на вашу електронну пошту',
    passwordResetMessage: 'Можете ввiйти використавши новий пароль',
    notMatchPasswordsMessage: 'Паролі не співпадають',
  },
  sidebarModal: {
    createButton: 'Готово',
    cancelButton: 'Вiдмiна',
  },
  projectsPage: {
    pageTitle: 'Проекти',
    addButton: 'Створити проект',
    projectCollection:
      'Ваша колекція проектів порожня, скористайтесь кнопкою "Створити проект"',
  },
  projectsPageForm: {
    formTitle: 'Створення проекту',
    projectName: 'Назва проекту',
    projectDescription: 'Опис',
  },
  projectPage: {
    addSprintButton: 'Створити спринт',
    addMembersButton: 'Додати людей',
    sprintCollection:
      'Ваша колекція спринтів порожня, скористайтесь кнопкою "Створити спринт"',
  },
  projectPageSidebar: {
    goBack: 'Показати проекти',
    addProjectButton: 'Створити проект',
  },
  projectPageForm: {
    formTitle: 'Створення спринта',
    sprintName: 'Назва спринта',
    prevDays: 'Попереднi днi',
    startDate: 'Дата початку',
    expireDate: 'Дата закiнчення', // изменил из "Дата закiнчення"
    duration: 'Тривалiсть',
  },
  addMembersForm: {
    formTitle: 'Додати людей',
    email: 'Введiть email',
    members: 'Доданi користувачi:',
  },
  sprintPageSidebar: {
    goBack: 'Показати спринти',
    addSprintButton: 'Створити спринт',
  },
  sprintPageHeader: {
    addTaskButton: 'Створити задачу',
    taskName: 'Задача',
    plannedHours: 'Заплановано годин',
    spentHoursPerDay: 'Витрачено год / день',
    spentHoursTotally: 'Витрачено годин',
    statusTitle: 'Не виконано/Виконано',
  },
  sprintPageForm: {
    formTitle: 'Створення задачi',
    taskName: 'Назва задачi',
    plannedHours: 'Заплановано годин',
  },
  sprintPage: {
    emptyTaskCollection:
      'Ваша колекція задач порожня, скористайтесь кнопкою "Створити задачу"',
    noSuchTask: 'Така задача вiдсутня',
    editProjectName: 'Введiть нову назву',
  },
  editTitleForm: {
    label: 'Введiть новий заголовок',
  },
  searchFormPlaceholder: 'Пошук...',
  locale: 'uk',
};

export { ukrainian };
