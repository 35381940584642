import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    wrappedComponentType: null,
  },
  reducers: {
    openModal(state, { payload }) {
      state.isOpen = true;
      state.wrappedComponentType = payload;
    },
    closeModal(state) {
      state.isOpen = false;
      state.wrappedComponentType = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
