import { createReducer } from '@reduxjs/toolkit';
import { loaderOn, loaderOff } from './loadingAction';
import {
  getTaskError,
  getTaskSuccess,
  getTaskRequest,
  addTaskRequest,
  addTaskSuccess,
  addTaskError,
  changeTaskHoursRequest,
  changeTaskHoursSuccess,
  changeTaskHoursError,
  deleteTaskRequest,
  deleteTaskSuccess,
  deleteTaskError,
} from '../tasks/taskActions';
import {
  getSprintRequest,
  getSprintSuccess,
  getSprintError,
  addSprintRequest,
  addSprintSuccess,
  addSprintError,
  changeTitleSprintRequest,
  changeTitleSprintSuccess,
  changeTitleSprintError,
  deleteSprintRequest,
  deleteSprintSuccess,
  deleteSprintError,
} from '../sprints/sprintActions';
import {
  getProjectRequest,
  getProjectSuccess,
  getProjectError,
  addProjectRequest,
  addProjectSuccess,
  addProjectError,
  addMemberProjectRequest,
  addMemberProjectSuccess,
  addMemberProjectError,
  changeTitleProjectRequest,
  changeTitleProjectSuccess,
  changeTitleProjectError,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectError,
} from '../projects/projectActions';
import {
  signinRequest,
  signinSuccess,
  signinError,
  signupRequest,
  signupSuccess,
  signupError,
  refreshRequest,
  refreshSuccess,
  refreshError,
  userSuccess,
  logoutRequest,
  logoutSuccess,
  logoutError,
} from '../auth/authActions';

const onLoader = () => true;
const offLoader = () => false;

export const isLoading = createReducer(false, {
  [loaderOn]: () => true,
  [loaderOff]: () => false,
  [getTaskError]: offLoader,
  [getTaskSuccess]: offLoader,
  [getTaskRequest]: onLoader,
  [getSprintRequest]: onLoader,
  [getSprintSuccess]: offLoader,
  [getSprintError]: offLoader,
  [getProjectRequest]: onLoader,
  [getProjectSuccess]: offLoader,
  [getProjectError]: offLoader,
  [addTaskRequest]: onLoader,
  [addTaskSuccess]: offLoader,
  [addTaskError]: offLoader,
  [addSprintRequest]: onLoader,
  [addSprintSuccess]: offLoader,
  [addSprintError]: offLoader,
  [addProjectRequest]: onLoader,
  [addProjectSuccess]: offLoader,
  [addProjectError]: offLoader,
  [addMemberProjectRequest]: onLoader,
  [addMemberProjectSuccess]: offLoader,
  [addMemberProjectError]: offLoader,
  [changeTitleSprintRequest]: onLoader,
  [changeTitleSprintSuccess]: offLoader,
  [changeTitleSprintError]: offLoader,
  [deleteSprintRequest]: onLoader,
  [deleteSprintSuccess]: offLoader,
  [deleteSprintError]: offLoader,
  [changeTaskHoursRequest]: onLoader,
  [changeTaskHoursSuccess]: offLoader,
  [changeTaskHoursError]: offLoader,
  [deleteTaskRequest]: onLoader,
  [deleteTaskSuccess]: offLoader,
  [deleteTaskError]: offLoader,
  [changeTitleProjectRequest]: onLoader,
  [changeTitleProjectSuccess]: offLoader,
  [changeTitleProjectError]: offLoader,
  [deleteProjectRequest]: onLoader,
  [deleteProjectSuccess]: offLoader,
  [deleteProjectError]: offLoader,
  [signinRequest]: onLoader,
  [signinSuccess]: offLoader,
  [signinError]: offLoader,
  [signupRequest]: onLoader,
  [signupSuccess]: offLoader,
  [signupError]: offLoader,
  [refreshRequest]: onLoader,
  [refreshSuccess]: offLoader,
  [refreshError]: offLoader,
  [userSuccess]: offLoader,
  [logoutRequest]: onLoader,
  [logoutSuccess]: offLoader,
  [logoutError]: offLoader,
});
