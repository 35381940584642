import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/authReducers';
import { sprintsReducer } from './sprints/sprintReducers';
import projectsReducer from './projects/projectReducers';
import { errorReducer } from './error/errorReducer';
import { isLoading } from './loading/loadingReducer';
import size from './size/sizeReducer';
import tasksReducer from './tasks/taskReducers';
import modal from './modal/modalSlice';
import search from './search/searchSlice';

const middleware = getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  });

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'ownProjects'],
};

const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    projects: projectsReducer,
    sprints: sprintsReducer,
    tasks: tasksReducer,
    search,
    modal,
    size,
    error: errorReducer,
    isLoading,
  },
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store);

export { store, persistor };
